
.pointer {
	cursor: pointer;
}

.input-focus-disabled {
	box-shadow: none !important;
}

.token-transfer-container {

	&:hover {
		.back-element {
			* {
				opacity: 0.9;
				transition: all 0.5s ease-in-out;
			}
		}
		.transfer-button {
			opacity: 1;
		}
	}
}

.fiat-currency-selected {
	background-color: rgba(0, 0, 0, 0.1);
}

.fiat-currency-select:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

/* 
* CSS Loader. 
* source: https://loading.io/css/
*/
.lds-ellipsis {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}

.lds-ellipsis div {
	position: absolute;
	top: 33px;
	width: 13px;
	height: 13px;
	border-radius: 50%;
	// background: #fff;
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
	left: 8px;
	animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
	left: 8px;
	animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
	left: 32px;
	animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
	left: 56px;
	animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes lds-ellipsis3 {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}

@keyframes lds-ellipsis2 {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(24px, 0);
	}
}
  